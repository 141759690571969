import type { Summary } from 'src/types/event.type';

import {
  createSlice,
  createAsyncThunk,
  type PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
import { API } from 'aws-amplify';

import { RootState } from './store';

const saveSummary = createAsyncThunk(
  'events/SAVE_SUMMARY',
  async ({ content }: { content: string }, thunkApi) => {
    const {
      events: { summary },
    } = thunkApi.getState() as RootState;
    const { PK, createdAt } = summary;

    return API.post(
      'conversationsApi',
      `/accounts/${summary.account}/bots/${summary.bot}/summaries/${PK}`,
      {
        headers: {
          'x-api-key': process.env.REACT_APP_CONVERSATIONSAPI_KEY,
        },
        body: { content, createdAt },
      },
    );
  },
);

const rateSummary = createAsyncThunk(
  'events/RATE_SUMMARY',
  async ({ rating, feedback }: { rating: number; feedback: string }, thunkApi) => {
    const {
      events: { summary },
    } = thunkApi.getState() as RootState;
    const { PK, createdAt } = summary;

    return API.post(
      'conversationsApi',
      `/accounts/${summary.account}/bots/${summary.bot}/summaries/${PK}/rate`,
      {
        headers: {
          'x-api-key': process.env.REACT_APP_CONVERSATIONSAPI_KEY,
        },
        body: { createdAt, feedback, rating },
      },
    );
  },
);

const validateSummary = createAsyncThunk('events/VALIDATE_SUMMARY', async (_, thunkApi) => {
  const {
    events: { summary },
  } = thunkApi.getState() as RootState;
  const { PK, createdAt, receivedAt } = summary;

  return API.post(
    'conversationsApi',
    `/accounts/${summary.account}/bots/${summary.bot}/summaries/${PK}/validate`,
    {
      headers: {
        'x-api-key': process.env.REACT_APP_CONVERSATIONSAPI_KEY,
      },
      body: { createdAt, receivedAt, validatedAt: Date.now() },
    },
  );
});

enum LoadingStatus {
  NONE = 'none',
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error',
}

interface EventsState {
  summary?: Summary;
  status: {
    copy: LoadingStatus;
    get: LoadingStatus;
    rate: LoadingStatus;
    save: LoadingStatus;
    validate: LoadingStatus;
  };
}

const initialState: EventsState = {
  summary: null,
  status: {
    copy: LoadingStatus.NONE,
    get: LoadingStatus.NONE,
    rate: LoadingStatus.NONE,
    save: LoadingStatus.NONE,
    validate: LoadingStatus.NONE,
  },
};

const { actions, reducer } = createSlice({
  name: 'events',
  initialState,
  reducers: {
    addSummary: (state, { payload }: PayloadAction<Summary>) => {
      // NOTE: Each summary is displayed in its own window. This condition prevent from overwriting current summary with a newer one
      if (!state.summary) state.summary = payload;
    },
    resetStatus: (state, { payload }: PayloadAction<string>) => {
      state.status[payload] = LoadingStatus.NONE;
    },
    setStatus: (state, { payload }: PayloadAction<{ label: string; status: LoadingStatus }>) => {
      state.status[payload.label] = payload.status;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(saveSummary.pending, state => {
        state.status.save = LoadingStatus.PENDING;
      })
      .addCase(saveSummary.rejected, state => {
        state.status.save = LoadingStatus.ERROR;
      })
      .addCase(saveSummary.fulfilled, (state, { meta }) => {
        state.status.save = LoadingStatus.SUCCESS;
        state.summary.content = meta.arg.content;
      })
      .addCase(rateSummary.pending, state => {
        state.status.rate = LoadingStatus.PENDING;
      })
      .addCase(rateSummary.rejected, state => {
        state.status.rate = LoadingStatus.ERROR;
      })
      .addCase(rateSummary.fulfilled, state => {
        state.status.rate = LoadingStatus.SUCCESS;
      })
      .addCase(validateSummary.pending, state => {
        state.status.validate = LoadingStatus.PENDING;
      })
      .addCase(validateSummary.rejected, state => {
        state.status.validate = LoadingStatus.ERROR;
      })
      .addCase(validateSummary.fulfilled, state => {
        state.status.validate = LoadingStatus.SUCCESS;
      });
  },
});

const selectSummary = (state: RootState) => state.events.summary || null;
const selectSummaryId = createSelector(selectSummary, (summary: Summary | null) => summary?.id);
const selectSummaryContent = createSelector(
  selectSummary,
  (summary: Summary | null) => summary?.content,
);
const selectAccount = createSelector(selectSummary, (summary: Summary | null) => summary?.account);
const selectBot = createSelector(selectSummary, (summary: Summary | null) => summary?.bot);
const selectStatus = (state: RootState) => state.events.status;
const selectStatusByLabel = createSelector(
  selectStatus,
  (_: RootState, label: string) => label,
  (status: Record<string, LoadingStatus>, label: string) => status[label],
);

export {
  actions,
  rateSummary,
  saveSummary,
  selectAccount,
  selectBot,
  selectStatusByLabel,
  selectSummary,
  selectSummaryContent,
  selectSummaryId,
  validateSummary,
  LoadingStatus as Status,
};
export default reducer;
