import { Card, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'src/hooks/store';
import { selectSummaryId } from 'src/state/events.state';

/**
 * The header component
 */
function HeaderComponent() {
  const { t } = useTranslation();

  const summaryId = useAppSelector(selectSummaryId);

  return (
    <div className="flex flex-col items-center pt-6">
      <Card className="m-6 m-auto rounded-3xl bg-primary">
        <Row className="h-full">
          <Col span={24} className="flex justify-center items-center h-full">
            <Typography.Text strong className="w-fit text-white text-2xl text-center">
              {t`Header.title`}
            </Typography.Text>
          </Col>
        </Row>
      </Card>
      {summaryId && (
        <b className="text-gray">
          <small>{summaryId}</small>
        </b>
      )}
    </div>
  );
}

export default HeaderComponent;
